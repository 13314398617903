import React, { Component } from 'react';
import PropTypes from 'prop-types';

const EpisodeContext = React.createContext();

export function SongProvider(props) {
  const [currentPlaying, setCurrentPlaying] = React.useState({});

  return (
    <EpisodeContext.Provider
      value={{
        state: currentPlaying,
        setCurrentPlaying,
      }}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    />
  );
}

/* eslint-disable react/prefer-stateless-function */
export class SongConsumer extends Component {
  render() {
    const { children } = this.props;
    return (
      <EpisodeContext.Consumer>{children}</EpisodeContext.Consumer>
    );
  }
}

SongConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};
