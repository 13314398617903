import React from 'react';
import Player from './src/components/player';
import { SongProvider, SongConsumer } from './src/components/context';

export const wrapPageElement = ({ element }) => {
  return (
    <SongProvider>
      <SongConsumer>
        {context => <Player song={context.state} />}
      </SongConsumer>
      {element}
    </SongProvider>
  )
}
