import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../assets/styles/components/header.scss';

const AudioPlayer = (props) => {
  const audioRef = useRef(null);
  const { song } = props;

  if (audioRef.current !== null) {
    audioRef.current.onloadedmetadata = () => {
      audioRef.current.play();
    };

    if (!song.play) {
      audioRef.current.pause();
    }

    if (song.play && audioRef.current.readyState > 0) {
      audioRef.current.play();
    }
  }

  return (
    <div>
      <audio ref={audioRef} src={song.soundFile}>
        <track kind="captions" src="" srcLang="en" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

AudioPlayer.propTypes = {
  song: PropTypes.shape().isRequired,
};

export default AudioPlayer;
